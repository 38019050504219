import groupsModule from '../groupsModule';

function exileUserController(
  $scope,
  $uibModalInstance,
  groupsService,
  modalData,
  $log,
  groupsConstants,
  languageResource,
  systemFeedbackService
) {
  'ngInject';

  $scope.params = modalData;

  $scope.exileUser = function () {
    // Check if the checkbox is checked or not
    if ($scope.layout.deleteWallPosts) {
      groupsService.deleteWallPostsByUser($scope.params.groupId, $scope.params.userId).then(
        function () {
          // Refresh the wall wallReloadDelay milliseconds after post deletion as bulk post deletion is executed asynchronously on backend.
          setTimeout(function () {
            modalData.refreshGroupWall();
          }, groupsConstants.wallReloadDelay);
        },
        function (data) {
          systemFeedbackService.warning(
            languageResource.get(groupsConstants.translations.deleteWallPostsByUserError)
          );
          $log.debug('--deleteWallPostsByUser-error---');
        }
      );
    }

    groupsService.exileUser($scope.params.groupId, $scope.params.userId).then(
      function () {
        modalData.reloadCurrentPage();
      },
      function (data) {
        if (data && data.errors && data.errors[0]) {
          const errorCode = data.errors[0].code;
          if (errorCode === groupsConstants.errorCodes.membership.operationUnavailable) {
            systemFeedbackService.warning(
              languageResource.get(groupsConstants.translations.groupMembershipsUnavailableError)
            );
          }
          // User may no longer exist in group, ignore errors
        }
      }
    );

    $uibModalInstance.close();
  };

  $scope.close = function () {
    $uibModalInstance.dismiss();
  };

  $scope.init = function () {
    $scope.layout = {
      deleteWallPosts: false
    };
  };

  $scope.init();
}

groupsModule.controller('exileUserController', exileUserController);
export default exileUserController;
