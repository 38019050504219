import groupsModule from '../groupsModule';
import { CurrentUser, ExperimentationService } from 'Roblox';

function groupExperimentsService(groupsConstants) {
  'ngInject';

  return {
    getLandingPageExperiment: async () => {
      var response = {
        useSearchLanding: false,
        isSearchV2: false
      };
      if (!CurrentUser.isAuthenticated) {
        return response;
      }

      try {
        const experimentConfig = await ExperimentationService.getAllValuesForLayer(
          groupsConstants.experimentLayer
        );

        if (experimentConfig.groupsLandingConfig) {
          const { isSearchV2, useSearchLanding } = experimentConfig.groupsLandingConfig;
          response.isSearchV2 = isSearchV2;
          response.useSearchLanding = useSearchLanding;
        }
      } catch (e) {
        /* no-op return control */
      }
      return response;
    },

    exposeLandingPageExperiment: () => {
      if (!CurrentUser.isAuthenticated) {
        return;
      }
      ExperimentationService.logLayerExposure(groupsConstants.experimentLayer);
    }
  };
}

groupsModule.factory('groupExperimentsService', groupExperimentsService);

export default groupExperimentsService;
