import groupsModule from '../groupsModule';

const eventConstants = {
  EventType: {
    CommunityDialogStateChanged: 'communityDialogStateChanged',
    GroupPageClickEvent: 'groupPageClickEvent',
    GroupPageExposureEvent: 'groupPageExposureEvent'
  },

  EventContext: {
    GroupHomepage: 'groupHomepage',
    GroupDiscovery: 'groupDiscovery',
    MyGroups: 'myGroups'
  },

  ExposureType: {
    GroupHomepage: 'groupHomepage',
    MyGroups: 'myGroups',
    GroupDiscovery: 'GroupDiscovery',
    GroupDiscoveryV2: 'GroupDiscoveryV2'
  },

  EventEntityType: {
    Group: 'group'
  }
};

groupsModule.constant('eventConstants', eventConstants);
