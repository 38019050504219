import groupsModule from '../groupsModule';
import { eventStreamService } from 'core-roblox-utilities';

function groupEventLoggingService(eventConstants) {
  'ngInject';

  return {
    logGroupPageExposureEvent({ context, groupId, exposureType, exposureId }) {
      eventStreamService.sendEventWithTarget(
        eventConstants.EventType.GroupPageExposureEvent,
        context,
        {
          groupId,
          exposureType,
          ...(exposureId && { exposureId }) // fixes type-checking issue when undefined
        }
      );
    },

    logGroupPageClickEvent({ context, groupId, clickTargetType, clickTargetId }) {
      eventStreamService.sendEventWithTarget(
        eventConstants.EventType.GroupPageClickEvent,
        context,
        {
          groupId,
          clickTargetType,
          ...(clickTargetId && { clickTargetId }) // fixes type-checking issue when undefined
        }
      );
    }
  };
}

groupsModule.factory('groupEventLoggingService', groupEventLoggingService);

export default groupEventLoggingService;
